import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { getFullUrl } from 'hubspot-url-utils';
import parse from './parse';
import isEmbedded from './isEmbedded';
import Scopes from '../constants/Scopes';
const REQUIRED_PARAMETERS = ['client_id', 'redirect_uri', 'scope'];
const portalId = PortalIdParser.get();
const BASE_URL = `/oauth/${portalId}`;
const BASE_URL_EMBEDDED = `/oauth/${portalId}/embedded`;
export const ROOT_URL_AUTH = isEmbedded ? BASE_URL_EMBEDDED : BASE_URL;
export const ROOT_URL_NO_AUTH = `/oauth`;
export const getNormalizedQuery = query => {
  if ('scopes' in query) {
    query.scope = query.scopes;
    delete query.scopes;
  }
  if ('scope' in query) {
    if (typeof query.scope === 'string') {
      if (query.scope.length > 0) {
        query.scope = query.scope.replace(/\+/g, ' ');
      }
    } else if (Array.isArray(query.scope)) {
      Raven.captureMessage('Unexpected duplicate query parameters', {
        extra: query
      });
      query.scope = query.scope[0].replace(/\+/g, ' ');
    } else {
      Raven.captureMessage('Unexpected query parameters', {
        extra: query
      });
    }
  }
  if ('optional_scopes' in query) {
    query.optional_scope = query.optional_scopes;
    delete query.optional_scopes;
  }
  if ('optional_scope' in query) {
    query.optional_scope = query.optional_scope.replace(/\+/g, ' ');
  }
  return query;
};
export const getMissingParameters = normalizedQuery => {
  const params = Object.keys(normalizedQuery);
  return REQUIRED_PARAMETERS.filter(value => !params.includes(value));
};
export const getAuthorizationRequestInfo = (queryParams, recaptchaToken) => {
  const hubId = PortalIdParser.get();
  const params = parse(queryParams.slice(1));
  // @ts-expect-error Argument of type 'Record<string, string | string[]>' is not assignable to parameter of type 'QueryType'. Property 'scope' is missing in type 'Record<string, string | string[]>' but required in type 'QueryType'.
  const normQuery = getNormalizedQuery(params);
  const queryState = normQuery.state;
  const {
    client_id,
    optional_scope,
    redirect_uri,
    response_type,
    scope = ''
  } = normQuery;
  const data = {
    clientId: client_id,
    hubId,
    optionalScopes: optional_scope ? optional_scope.split(' ') : [],
    recaptchaToken: recaptchaToken,
    redirectUri: redirect_uri,
    responseType: response_type || 'code',
    scopes: Array.isArray(scope) ? scope : scope.split(' ')
  };
  if (queryState) {
    data.state = encodeURIComponent(queryState);
  }
  return data;
};
export const getOauthBridgeUrl = queryParams => {
  const baseUrl = getFullUrl('app');
  const params = new URLSearchParams(queryParams);
  const client_id = params.get('client_id');
  const redirect_uri = params.get('redirect_uri');
  const scope = params.get('scope');
  const state = params.get('state');
  const optional_scope = params.get('optional_scope');
  const encodedUri = encodeURIComponent(redirect_uri);
  let url = `${baseUrl}/oauth-bridge?client_id=${client_id}&redirect_uri=${encodedUri}&scope=${scope}&state=${state}`;
  if (optional_scope) {
    url += `&optional_scope=${optional_scope}`;
  }
  return url;
};
export const isOnlyRequestingOAuthScope = search => {
  const parsedSearch = parse(search.slice(1));
  const scopes = parsedSearch.scope;
  const optionalScopes = parsedSearch.optional_scopes;
  const scopeSet = new Set([...(scopes ? scopes.split(' ') : []), ...(optionalScopes ? optionalScopes.split(' ') : [])]);
  return scopeSet.size === 1 && scopeSet.has(Scopes.OAUTH);
};