import { POPOVER_PADDING_X } from 'HubStyleTokens/sizes';
export const RECAPTCHA_LOADING_STATUS = {
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
};
export const RECAPTCHA_HEIGHT = 78;
export const RECAPTCHA_WIDTH = 304;
export const RECAPTCHA_POPOVER_WIDTH = RECAPTCHA_WIDTH + parseInt(POPOVER_PADDING_X.slice(0, -2), 10) * 2;
export const RECAPTCHA_SCRIPT_ID = 'google-recaptcha-v3';