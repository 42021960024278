module.exports = {
  "/authorize": {
    "name": "authorize",
    "success": {
      "default": [
        "AUTH_PAGE_LOAD"
      ]
    },
    "error": [
      "AUTH_PAGE_LOAD_ERROR"
    ]
  }
};