import { createSlice } from '@reduxjs/toolkit';
import { List } from 'immutable';
import Account from 'developer-experience-shared-components/accounts/models/Account';
import RequestStatus from '../data/models/RequestStatus';
import FrontendPagination from 'developer-experience-shared-components/lib/FrontendPagination';
import { fetchAccountById, fetchAccounts } from '../actions/AccountActions';
export const defaultState = {
  accountsFetchStatus: RequestStatus.uninitialized,
  accounts: [],
  accountsPagination: new FrontendPagination()
};
const accountsSlice = createSlice({
  name: 'accounts',
  initialState: defaultState,
  reducers: {
    changePage: (state, action) => {
      state.accountsPagination = state.accountsPagination.set('page', action.payload);
    },
    search: (state, action) => {
      state.accountsPagination = state.accountsPagination.set('items', List(state.accounts.filter(acc => acc.accountName.toLowerCase().includes(action.payload) || acc.domain.toLowerCase().includes(action.payload) || acc.id.toString().includes(action.payload)))).set('page', 1);
    }
  },
  extraReducers: builder => builder.addCase(fetchAccounts.pending, state => {
    state.accountsFetchStatus = RequestStatus.pending;
  }).addCase(fetchAccounts.rejected, state => {
    state.accountsFetchStatus = RequestStatus.failed;
  }).addCase(fetchAccounts.fulfilled, (state, action) => {
    const accounts = action.payload.accounts.map(account => new Account(account));
    state.accountsFetchStatus = RequestStatus.succeeded;
    state.accounts = accounts;
    state.accountsPagination = state.accountsPagination.set('items', List(accounts));
  }).addCase(fetchAccountById.pending, state => {
    state.accountsFetchStatus = RequestStatus.pending;
  }).addCase(fetchAccountById.rejected, state => {
    state.accountsFetchStatus = RequestStatus.failed;
  }).addCase(fetchAccountById.fulfilled, (state, {
    payload
  }) => {
    state.accountsFetchStatus = RequestStatus.succeeded;
    state.accounts = [new Account(payload)];
  })
});
export const {
  search,
  changePage
} = accountsSlice.actions;
export default accountsSlice.reducer;