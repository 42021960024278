import { CAN_INSTALL_INTO_DEV_PORTAL } from '../constants/Scopes';
import auth from './Auth';
export const getAuthData = () => {
  const {
    user,
    gates,
    portal,
    scopes
  } = auth.get();
  return {
    user,
    gates,
    portal,
    scopes
  };
};
export const checkForNewRequestedScopes = (requestedScopes, currentScopes) => {
  // If the app has not been previously installed,
  if (currentScopes.length === 0) {
    return true;
  }

  // Checks if the requested scopes has some subset (or all) of the scopes in the installed scopes
  const hasSameScopes = requestedScopes.every(scope => currentScopes.includes(scope));
  return !hasSameScopes;
};
export const getShouldAllowDevPortalInstall = authorizationData => {
  // check to make sure the scopes equal oauth and or openid
  return authorizationData.scopeGroups.every(scope => CAN_INSTALL_INTO_DEV_PORTAL.includes(scope.name));
};
const UUIDRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const isValidClientId = str => {
  return UUIDRegex.test(str);
};