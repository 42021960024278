import PortalIdParser from 'PortalIdParser';
import httpNoPortal from 'hub-http/clients/noPortalApiClient';
import httpPortal from 'hub-http/clients/apiClient';
const ACCOUNTS_API_BASE = '/accounts/v1/accounts/cross-hublet';
export const fetchAccounts = () => {
  return httpNoPortal.get(ACCOUNTS_API_BASE);
};
export const fetchAccountById = () => {
  const portalId = PortalIdParser.get();
  return httpPortal.get(`hubs/v1/hubs/${portalId}`);
};