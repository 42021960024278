import { Record, List } from 'immutable';
export default class Account extends Record({
  accountName: null,
  additionalLoginDestination: null,
  appDomain: null,
  cookieWeight: 0,
  createdAt: null,
  domain: null,
  hublet: null,
  id: 0,
  numberOfContacts: 0,
  numberOfUsers: 0,
  obscured: false,
  products: List()
}) {
  constructor(data) {
    super(Object.assign({}, data, {
      products: List(data.products)
    }));
  }
}