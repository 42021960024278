import { RECAPTCHA_SCRIPT_ID } from './constants';
const cleanGstaticRecaptchaScript = () => {
  const script = document.querySelector(`script[src^='https://www.gstatic.com/recaptcha/releases']`);
  if (script) {
    script.remove();
  }
};
export const isScriptInjected = scriptId => !!document.querySelector(`#${scriptId}`);
export const cleanGoogleRecaptcha = scriptId => {
  // remove badge
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  // remove script
  const script = document.querySelector(`#${scriptId}`);
  if (script) {
    script.remove();
  }
  cleanGstaticRecaptchaScript();
};
export const injectGoogleReCaptchaScript = ({
  googleRecaptchaSrc,
  onLoad,
  onError
}) => {
  // Script has already been injected, just call onLoad and do nothing else
  if (isScriptInjected(RECAPTCHA_SCRIPT_ID)) {
    onLoad();
    return;
  }
  const js = document.createElement('script');
  js.id = RECAPTCHA_SCRIPT_ID;
  js.src = googleRecaptchaSrc;
  js.defer = true;
  js.async = true;
  js.onload = onLoad;
  if (onError) {
    js.onerror = onError;
  }
  document.body.appendChild(js);
};