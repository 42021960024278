// @ts-expect-error untyped
import iconNames from 'icons/iconNames';
import PICTOS_TO_HUBSPOT_ICONS from '../constants/PictosMap';
export const getIcon = picto => {
  if (picto) {
    if (iconNames.includes(picto)) {
      return picto;
    }
    if (picto && PICTOS_TO_HUBSPOT_ICONS[picto]) {
      return PICTOS_TO_HUBSPOT_ICONS[picto];
    }
  }
  return 'integrations';
};