import userInfo from 'hub-http/userInfo';
import { fetchUserInfoNoPortal } from '../data/api/UserHubsClient';
function getUserInfo({
  hubless
}) {
  return hubless ? fetchUserInfoNoPortal() : userInfo();
}
class Auth {
  createInstance() {
    return new Auth();
  }
  constructor() {
    this.data = {
      auth: null,
      user: null,
      gates: null,
      portal: null
    };
    this.initialized = false;
  }
  init({
    hubless
  }) {
    if (this.initialized) {
      throw new Error('Auth Utils: Cannot be initialized twice. Use getInstance() if you ' + 'need to create multiple instances.');
    }
    this.initialized = true;
    return getUserInfo({
      hubless
    }).then(resp => {
      this.data = Object.assign({}, resp);
      return this.data;
    });
  }
  get() {
    return this.data;
  }
}
export default new Auth();