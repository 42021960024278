import { createSlice } from '@reduxjs/toolkit';
import RequestStatus from '../data/models/RequestStatus';
import { fetchEcosystemDetails } from '../actions/EcosystemActions';
export const defaultState = {
  details: {
    data: null,
    status: RequestStatus.uninitialized
  }
};
const EcosystemSlice = createSlice({
  name: 'ecosystem',
  initialState: defaultState,
  reducers: {},
  extraReducers: builder => builder.addCase(fetchEcosystemDetails.pending, state => {
    state.details.status = RequestStatus.pending;
  }).addCase(fetchEcosystemDetails.rejected, (state, {
    meta
  }) => {
    // If the app was not found, then still show the request status as succeeded
    if (meta.status === 404) {
      state.details.status = RequestStatus.succeeded;
    } else {
      state.details.status = RequestStatus.failed;
    }
  }).addCase(fetchEcosystemDetails.fulfilled, (state, {
    payload
  }) => {
    state.details.status = RequestStatus.succeeded;
    state.details.data = payload;
  })
});
export default EcosystemSlice.reducer;