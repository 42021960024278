import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import Raven from 'raven-js';
import enviro from 'enviro';
function isDebug() {
  return enviro.debug('oauth-ui') && (enviro.isQa('oauth-ui') || !enviro.deployed('oauth-ui'));
}
function isAbortedRequest(action) {
  return !!action.payload && !!action.payload.errorCode && action.payload.errorCode === 'ABORT';
}
function isNetworkError(action) {
  return !!action.payload && !!action.payload.error && action.payload.error.status === 0;
}
const sendFailuresToSentry = () => next => action => {
  if (action.type.indexOf('_FAILED') > -1 && !isAbortedRequest(action) && !isNetworkError(action)) {
    Raven.captureMessage(action.type, {
      extra: {
        action
      }
    });
    if (isDebug()) {
      console.error('Debug output:', action.type, {
        extra: {
          action
        }
      });
    }
  }
  return next(action);
};
const sendExceptionsToSentry = () => next => action => {
  try {
    return next(action);
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        action
      }
    });
    if (isDebug()) {
      console.error(err, {
        extra: {
          action
        }
      });
    }
    throw err;
  }
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, sendFailuresToSentry, sendExceptionsToSentry))(createStore);
export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}