import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEcosystemDetails } from '../data/api/EcosystemClient';
export const fetchEcosystemDetails = createAsyncThunk('ecosystems/details', (appId, {
  rejectWithValue
}) => {
  return getEcosystemDetails(appId).catch(e => {
    return rejectWithValue(appId, {
      status: e.status
    });
  });
});