import { useMemo, useState, useEffect, useCallback } from 'react';
import { RECAPTCHA_SCRIPT_ID } from '../constants';
import { cleanGoogleRecaptcha, injectGoogleReCaptchaScript } from '../utils';
export function useRecaptchav3({
  reCaptchaKey
}) {
  const [greCaptchaInstance, setGreCaptchaInstance] = useState(null);
  useEffect(() => {
    if (!reCaptchaKey) {
      return;
    }
    const onLoad = () => {
      if (!window || !window.grecaptcha) {
        return;
      }
      window.grecaptcha.enterprise.ready(() => {
        setGreCaptchaInstance(window.grecaptcha.enterprise);
      });
    };
    const onError = () => {
      cleanGoogleRecaptcha(RECAPTCHA_SCRIPT_ID);
      // Fall back to recaptcha.net in case google.com is unavailable
      // source: https://cloud.google.com/recaptcha-enterprise/docs/faq#can_i_use_globally
      injectGoogleReCaptchaScript({
        googleRecaptchaSrc: `https://www.recaptcha.net/recaptcha/enterprise.js?render=${reCaptchaKey}`,
        onLoad
      });
    };
    injectGoogleReCaptchaScript({
      googleRecaptchaSrc: `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaKey}`,
      onLoad,
      onError
    });

    // eslint-disable-next-line consistent-return
    return () => {
      cleanGoogleRecaptcha(RECAPTCHA_SCRIPT_ID);
    };
  }, [reCaptchaKey]);
  const executeRecaptcha = useCallback(action => {
    return greCaptchaInstance.execute(reCaptchaKey, {
      action
    });
  }, [greCaptchaInstance, reCaptchaKey]);
  return useMemo(() => ({
    executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined
  }), [executeRecaptcha, greCaptchaInstance]);
}