import http from 'hub-http/clients/apiClient';
export function authorize(data) {
  return http.post('oauth/v2/auth', {
    data
  })
  // v1 calls it redirectUri
  .then(({
    redirectUrl
  }) => ({
    redirectUri: redirectUrl
  }));
}