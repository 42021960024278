import { combineReducers } from 'redux';
import auth from './auth';
import app from './app';
import accounts from './accounts';
import ecosystem from './ecosystem';
const rootReducer = combineReducers({
  auth,
  app,
  accounts,
  ecosystem
});
export default rootReducer;