import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import memoizeOne from 'react-utils/memoizeOne';
import I18n from 'I18n';
import { getScopeGroupI18nKey } from './getScopeGroupI18nKey';
import { OPERATIONS_LIST } from '../constants/Operations';
import { SCOPE_CATEGORY_NAMES } from '../constants/ScopeCategoryNames';
import Scope from '../models/Scope';
const createScope = ({
  scope,
  scopesWithExtraData,
  splitScope = null,
  isRecommended = null,
  isLegacy = false
} = {}) => {
  let translatedGroupName = '';
  let groupedScopeName = '';
  let scopeWithoutOperation = '';
  if (splitScope) {
    // Group it with like scopes, by operation
    scopeWithoutOperation = splitScope.length > 2 ? splitScope.slice(0, -1).join('.') : scope.name;
    groupedScopeName = splitScope.length > 3 ? splitScope.slice(1, -1).join('.') : splitScope[1];
    translatedGroupName = I18n.text(getScopeGroupI18nKey(scopeWithoutOperation, 'label'), {
      defaultValue: groupedScopeName
    });
  }
  const translatedName = I18n.text(getScopeGroupI18nKey(scope.name, 'label'), {
    defaultValue: isLegacy ? scope.name : scope.shortDescription.trim() || scope.name
  });
  const translatedDescription = I18n.text(getScopeGroupI18nKey(scope.name, 'description'), {
    defaultValue: scope.longDescription
  });
  const scopeWithExtraData = new Scope(Object.assign({}, scope, {
    translatedName,
    translatedDescription,
    recommended: isRecommended
  }));
  scopesWithExtraData.push([scope.id, scopeWithExtraData]);
  return {
    name: scopeWithoutOperation,
    groupedScopeName,
    translatedGroupName,
    scopesWithExtraData,
    scopeWithExtraData
  };
};
const handleGranularScope = (scopeTree, scope, scopesWithExtraData, splitScope, withIds, isRecommended) => {
  // Create child object for top-level scope category
  if (!(splitScope[0] in scopeTree)) {
    // @ts-expect-error immutable
    scopeTree[splitScope[0]] = withIds ? {
      scopes: {},
      scopeIds: []
    } : {}; // This needs to be cast because of immutable
  }
  const {
    groupedScopeName,
    translatedGroupName,
    scopeWithExtraData,
    name
  } = createScope({
    scope,
    scopesWithExtraData,
    splitScope,
    isRecommended
  });
  if (withIds) {
    if (!(groupedScopeName in scopeTree[splitScope[0]].scopes)) {
      // Store the translated name/description for all operations in this group
      // of scopes
      scopeTree[splitScope[0]].scopes[groupedScopeName] = {
        translatedName: translatedGroupName,
        name: name
      };
    }
    // Store the scope itself
    scopeTree[splitScope[0]].scopes[groupedScopeName][splitScope[splitScope.length - 1]] = scopeWithExtraData;
    // @ts-expect-error immutable
    scopeTree[splitScope[0]].scopeIds.push(scope.id);
  } else {
    if (!(groupedScopeName in scopeTree[splitScope[0]])) {
      // Store the translated name/description for all operations in this group
      // of scopes
      scopeTree[splitScope[0]][groupedScopeName] = {
        translatedName: translatedGroupName,
        name: name
      };
    }
    // Store the scope itself
    scopeTree[splitScope[0]][groupedScopeName][splitScope[splitScope.length - 1]] = scopeWithExtraData;
  }
};
const handleLegacyScope = (scopeTree, scope, scopesWithExtraData, withIds) => {
  const {
    scopeWithExtraData
  } = createScope({
    scope,
    scopesWithExtraData,
    isLegacy: true
  });
  if (withIds) {
    scopeTree.legacy.scopes[scope.id] = scopeWithExtraData;
  } else {
    scopeTree.legacy[scope.id] = scopeWithExtraData;
  }
  scopesWithExtraData.push([scope.id, scopeWithExtraData]);
};

/**
 * A native JavaScript object representing a scopegroup, with the information
 * returned by the backend.
 * @typedef {Object} ScopeObject
 * @property {number} id    - Scopegroup ID
 * @property {string} longDescription - English description of the scopegroup
 * @property {string} name  - Machine-readable name of scopegroup
 * @property {string} shortDescription - English human-readable name of sg
 * @property {string} version - Scopegroup version (GRANULAR or LEGACY)
 * @property {string} visibility  - Scopegroup visibility (HIDDEN or VISIBLE)
 * @property {boolean} whitelisted - Whether the sg is whitelisted
 */

/**
 * Transform an array or Immutable.List of ScopeObjects into a tree
 * representing the granular scope heirarchy, organized by category. Optionally
 * also include a Set of all scope IDs in a given category, if withIds is true.
 *
 * @param {Array<ScopeObject>|Immutable.List<ScopeObject>} _scopesList
 * @param {boolean} [withIds=true] Whether a Set of all scope IDs should be included in each category in the output object
 * @param {Immutable.List<string>} [recommendedScopes=null] List of names of scopes that are recommended
 * @returns {Object}
 */
export const createScopesTree = (_scopesList, withIds = true, recommendedScopes = null) => {
  let scopesList;
  try {
    // FIXME: should be `as ScopeArgs`
    scopesList = _scopesList.map(sg => sg.toJS());
  } catch (err) {
    scopesList = _scopesList;
  }
  const scopesWithExtraData = [];
  const scopeTree = {};
  scopesList.forEach(s => {
    const splitScope = s.name.split('.');
    if (SCOPE_CATEGORY_NAMES.includes(splitScope[0]) && splitScope.length > 2 && OPERATIONS_LIST.includes(splitScope[splitScope.length - 1])) {
      const isRecommended = !!(recommendedScopes && recommendedScopes.includes(s.name));
      handleGranularScope(scopeTree, s, scopesWithExtraData, splitScope, withIds, isRecommended);
    } else {
      if (!('legacy' in scopeTree)) {
        scopeTree.legacy = withIds ? {
          scopes: {}
        } : {};
      }
      handleLegacyScope(scopeTree, s, scopesWithExtraData, withIds);
    }
    return scopeTree;
  });

  // Transform the arrays of scopeIds into Sets
  if (withIds) {
    for (const key of Object.keys(scopeTree)) {
      if ('scopeIds' in scopeTree[key]) {
        scopeTree[key].scopeIds = ImmutableSet(scopeTree[key].scopeIds);
      } else {
        scopeTree[key].scopeIds = ImmutableSet(Object.keys(scopeTree[key].scopes).map(id => parseInt(id, 10)));
      }
    }
  }
  return scopeTree;
};

/**
 * Create a Map of scopes (keyed by ID) with translated names and a flag
 * marking if the scope is legacy or not.
 *
 * @param {Array.<ScopeObject>|Immutable.List<ScopeObject>} _scopesList
 * @returns {Immutable.Map}
 */
export const createScopes = _scopesList => {
  let scopesList;
  try {
    scopesList = _scopesList.map(sg => sg.toJS());
  } catch (err) {
    scopesList = _scopesList;
  }
  const scopesWithExtraData = [];
  scopesList.forEach(scope => {
    const splitScope = scope.name.split('.');
    if (SCOPE_CATEGORY_NAMES.includes(splitScope[0]) && splitScope.length > 2 && OPERATIONS_LIST.includes(splitScope[splitScope.length - 1])) {
      return createScope({
        scope,
        scopesWithExtraData,
        splitScope
      });
    } else {
      return createScope({
        scope,
        scopesWithExtraData,
        isLegacy: true
      });
    }
  });
  return ImmutableMap(scopesWithExtraData);
};
export const memoizedCreateScopesTree = memoizeOne(createScopesTree);