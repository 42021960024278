const ScopeCategoryIcon = {
  analytics: 'generateChart',
  automation: 'workflows',
  'communication-preferences': 'notification',
  crm: 'contacts',
  cms: 'website',
  events: 'dynamicFilter',
  files: 'file',
  marketing: 'email',
  settings: 'settings'
};
export { ScopeCategoryIcon };