const getIsImpersonated = state =>
// @ts-expect-error bad typings from User, but keeping this as is for potential regressions
state.auth.user.acting_user_id;
export const getErrorFields = (err, state) => {
  const {
    responseJSON
  } = err;
  const isImpersonated = getIsImpersonated(state);
  const status = responseJSON && responseJSON.status;
  const message = responseJSON && responseJSON.message;
  const subCategory = responseJSON && responseJSON.subCategory;
  const context = responseJSON && responseJSON.context;
  return {
    status,
    message,
    errorCode: status === 'error' && isImpersonated ? 'IMPERSONATION_UNAUTHORIZED' : status,
    context,
    subCategory: status === 'error' && isImpersonated ? 'errors.IMPERSONATION_UNAUTHORIZED' : subCategory
  };
};