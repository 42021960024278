import memoizeOne from 'react-utils/memoizeOne';
/**
 * Creates a sorted array of scope categories for use in the ScopesInput
 * component.
 *
 * @param {Object} scopesTree - Object keyed by category, containing the
 * scopes tree and scopeId Set. Generated by utils/createScopeTree
 * @param {Array.<string>} [nonGranularScopeCategories] - Optional array of
 * non-granular scope categories, which will be grouped last
 * @return {Array.<{category: string, isGranular: boolean}>} - Array of objects
 * containing the category name and an isGranular flag
 */
export const sortScopesCategories = (scopesTree, nonGranularScopeCategories = []) => {
  if (!scopesTree) return [];
  // Sort granular scope categories alphabetically, then list any non-granular
  // scope categories at the end.
  const categories = Object.keys(scopesTree);
  categories.sort();
  const sortedCategories = [];
  categories.forEach(c => {
    if (nonGranularScopeCategories.length === 0 && c === 'legacy') {
      // If no list of non-granular scopes was specified, we can still pretty
      // safely assume that scopes in the "legacy" category are not granular.
      nonGranularScopeCategories.push('legacy');
    }
    if (!nonGranularScopeCategories.includes(c)) {
      sortedCategories.push({
        category: c,
        isGranular: true
      });
    }
  });
  nonGranularScopeCategories.forEach(c => sortedCategories.push({
    category: c,
    isGranular: false
  }));
  return sortedCategories;
};
export const memoizedSortScopesCategories = memoizeOne(sortScopesCategories);