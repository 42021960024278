import { Record } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { SCOPE_VERSIONS_LIST } from '../constants/ScopeVersions';
import { getIcon } from '../utils/icons';
const defaults = {
  enabled: true,
  id: null,
  longDescription: null,
  name: null,
  pictoIcon: null,
  icon: null,
  shortDescription: null,
  translatedName: null,
  translatedDescription: null,
  version: null,
  visibility: null,
  whitelisted: null,
  recommended: null,
  portalAuthorized: true
};
class Scope extends Record(defaults) {
  constructor(data) {
    let scopeData = data;
    // this prop is included in the new data model from BE to determine if a scope is portalAuthorized.
    if ('scopeGroup' in scopeData) {
      scopeData = Object.assign({}, data, data.scopeGroup);
    }
    // Map picto icon to HubSpot icon
    const picto = scopeData.pictoIcon;
    const icon = getIcon(picto);
    super(Object.assign({}, scopeData, {
      icon
    }));
  }
}
export default Scope;
export const ScopePropType = PropTypes.instanceOf(Scope);
export const GenericScopePropType = PropTypes.arrayOf(PropTypes.oneOfType([ScopePropType, ImmutablePropTypes.recordOf({
  id: PropTypes.number,
  longDescription: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.oneOf(SCOPE_VERSIONS_LIST)
})]).isRequired);