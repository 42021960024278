import { Record } from 'immutable';
import Scope from 'developer-experience-shared-components/scopes/models/Scope';
export const DOMAIN_VERIFICATION_STATUS = {
  NO_DOMAIN: 'NO_DOMAIN',
  UNVERIFIED_DOMAIN: 'UNVERIFIED_DOMAIN',
  VERIFIED_DOMAIN: 'VERIFIED_DOMAIN'
};
export const LISTING_STATUS = {
  UNLISTED: 'UNLISTED',
  PUBLISHED: 'PUBLISHED',
  CERTIFIED: 'CERTIFIED'
};
const defaults = {
  clientId: '',
  appId: null,
  name: '',
  shortDescription: '',
  description: '',
  iconUrl: '',
  listingIconUrl: '',
  scopeGroups: [],
  authorized: false,
  provider: {
    certified: false,
    domain: null,
    published: false,
    verified: false
  }
};
class ApplicationAuthorization extends Record(defaults) {
  constructor(data) {
    super(Object.assign({}, data, {
      scopeGroups: data.scopeGroups.map(sg => new Scope(sg))
    }));
  }
  getDomainVerificationStatus() {
    if (!this.provider.domain) {
      return DOMAIN_VERIFICATION_STATUS.NO_DOMAIN;
    } else if (this.provider.verified) {
      return DOMAIN_VERIFICATION_STATUS.VERIFIED_DOMAIN;
    }
    return DOMAIN_VERIFICATION_STATUS.UNVERIFIED_DOMAIN;
  }
  getListingStatus() {
    if (this.provider.certified) {
      return LISTING_STATUS.CERTIFIED;
    } else if (this.provider.published) {
      return LISTING_STATUS.PUBLISHED;
    }
    return LISTING_STATUS.UNLISTED;
  }
}
export default ApplicationAuthorization;