const PICTOS_TO_HUBSPOT_ICONS = {
  settings: 'settings',
  person: 'contacts',
  integrations: 'integrations',
  social: 'social',
  integration: 'integrations',
  cube: 'integrations',
  email: 'email',
  company: 'companies',
  upload: 'upload',
  forms: 'forms',
  'source-code': 'code',
  events: 'dynamicFilter',
  'generic-file': 'file',
  tools: 'customModules',
  success: 'success',
  workflows: 'workflows',
  money: 'salesQuote',
  bubble: 'bubble',
  sources: 'generateChart',
  folder: 'folder',
  'conversion-assists': 'generateChart',
  forums: 'questionAnswer',
  lists: 'lists',
  'landing-pages': 'section',
  metric: 'generateChart',
  services: 'lists'
};
export default PICTOS_TO_HUBSPOT_ICONS;