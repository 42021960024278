const Scopes = {
  OPEN_ID: 'openid',
  OAUTH: 'oauth',
  DEVELOPERS_READ: 'developers-read',
  CRM_ACCESS: 'crm-access',
  FULL_CRM_ACCESS: 'full-crm-access',
  INTEGRATIONS_MANAGEMENT_WRITE: 'integrations-management-write',
  DEVELOPER_PORTAL_ACCESS: 'developer-portal-access'
};
export const CAN_INSTALL_INTO_DEV_PORTAL = [Scopes.OPEN_ID, Scopes.OAUTH];
export default Scopes;