//I18n Configuration
import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import registerAllData from 'I18n/init/register/sync/registerAllData';
import { initAppTranslations } from 'I18n/init/setup/AppInit';

// Import extra data
import 'I18n/init/data/currency';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/publicLocales';
const i18nSetup = {
  provider: null,
  setup: function setup({
    lang,
    manualLocaleSettings
  }) {
    let options;
    if (manualLocaleSettings) {
      options = {
        manuallySetLocale: true
      };
    }
    this.provider = createPublicI18nProvider(options);
    const langAvailable = initAppTranslations([this.provider.register(lang), registerAllData(this.provider)]);
    if (manualLocaleSettings) {
      this.provider.setLocale({
        locale: manualLocaleSettings.locale,
        langEnabled: manualLocaleSettings.langEnabled
      });
    }
    return langAvailable;
  }
};
export default i18nSetup;