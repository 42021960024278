import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { ACCOUNTS_PER_PAGE } from '../accounts/constants/AccountPagination';
const defaults = {
  // @ts-expect-error immutable
  items: [],
  page: 1,
  perPage: ACCOUNTS_PER_PAGE
};
const getItems = pagination => pagination.items;
const getPage = pagination => pagination.page;
const getPerPage = pagination => pagination.perPage;
const getPageCount = createSelector([getItems, getPerPage], (items, perPage) => {
  return Math.ceil(items.size / perPage);
});
const getVisible = createSelector([getItems, getPage, getPerPage], (items, page, perPage) => {
  return items.slice((page - 1) * perPage, page * perPage);
});
class FrontendPagination extends Record(defaults) {
  getItems() {
    return getItems(this);
  }
  setItems(items) {
    return this.set('items', items);
  }
  getPage() {
    return getPage(this);
  }
  setPage(page) {
    return this.set('page', page);
  }
  getPerPage() {
    return getPerPage(this);
  }
  setPerPage(perPage) {
    return this.set('perPage', perPage);
  }
  getPageCount() {
    return getPageCount(this);
  }
  getVisible() {
    return getVisible(this);
  }
}
export default FrontendPagination;